<template>
  <div class="columns is-multiline is-mobile">
    <a class="column is-4 has-text-dark" @click="scroll('stolen')">
      <div class="columns is-vcentered">
        <div class="column is-narrow">
          <button
            class="button"
            :class="[data.stolen ? 'is-danger' : 'is-success']"
          >
            &nbsp;&nbsp;
          </button>
        </div>
        <div class="column">
          <span v-if="data.stolen">Stolen</span>
          <span v-else>Not stolen</span>
        </div>
      </div>
    </a>
    <a class="column is-4 has-text-dark" @click="scroll('risk')">
      <div class="columns is-vcentered">
        <div class="column is-narrow">
          <button
            class="button"
            :class="[data.risk ? 'is-danger' : 'is-success']"
          >
            &nbsp;&nbsp;
          </button>
        </div>
        <div class="column">
          <span v-if="data.risk">High risk</span>
          <span v-else>Not high risk</span>
        </div>
      </div>
    </a>
    <a class="column is-4 has-text-dark" @click="scroll('finance')">
      <div class="columns is-vcentered">
        <div class="column is-narrow">
          <button
            class="button"
            :class="[data.finance ? 'is-warning' : 'is-success']"
          >
            &nbsp;&nbsp;
          </button>
        </div>
        <div class="column">
          <span v-if="data.finance">Finance reported</span>
          <span v-else>No finance</span>
        </div>
      </div>
    </a>
    <div class="column is-12">
      <hr class="hr is-marginless" />
    </div>
    <a class="column is-4 has-text-dark" @click="scroll('damage')">
      <div class="columns is-vcentered">
        <div class="column is-narrow">
          <button
            class="button"
            :class="[data.damage ? 'is-danger' : 'is-success']"
          >
            &nbsp;&nbsp;
          </button>
        </div>
        <div class="column">
          <span v-if="data.damage">Damage reported</span>
          <span v-else>No damage</span>
        </div>
      </div>
    </a>
    <a class="column is-4 has-text-dark" @click="scroll('scrapped')">
      <div class="columns is-vcentered">
        <div class="column is-narrow">
          <button
            class="button"
            :class="[data.scrapped ? 'is-danger' : 'is-success']"
          >
            &nbsp;&nbsp;
          </button>
        </div>
        <div class="column">
          <span v-if="data.scrapped">Scrapped</span>
          <span v-else>Not scrapped</span>
        </div>
      </div>
    </a>
    <a class="column is-4 has-text-dark" @click="scroll('plate')">
      <div class="columns is-vcentered">
        <div class="column is-narrow">
          <button class="button" :class="getColour(data.plate)">
            &nbsp;&nbsp;
          </button>
        </div>
        <div class="column">
          <span v-if="data.plate">Plate changes</span>
          <span v-else>No plate changes</span>
        </div>
      </div>
    </a>
    <div class="column is-12">
      <hr class="hr is-marginless" />
    </div>
    <a class="column is-4 has-text-dark" @click="scroll('exported')">
      <div class="columns is-vcentered">
        <div class="column is-narrow">
          <button
            class="button"
            :class="[data.export ? 'is-danger' : 'is-success']"
          >
            &nbsp;&nbsp;
          </button>
        </div>
        <div class="column">
          <span v-if="data.export">Exported</span>
          <span v-else>Not exported</span>
        </div>
      </div>
    </a>
    <a class="column is-4 has-text-dark" @click="scroll('imported')">
      <div class="columns is-vcentered">
        <div class="column is-narrow">
          <button
            class="button"
            :class="[data.import ? 'is-warning' : 'is-success']"
          >
            &nbsp;&nbsp;
          </button>
        </div>
        <div class="column">
          <span v-if="data.import">Imported</span>
          <span v-else>Not imported</span>
        </div>
      </div>
    </a>
    <a class="column is-4 has-text-dark" @click="scroll(extraSection)">
      <div class="columns is-vcentered">
        <div class="column is-narrow">
          <button class="button" :class="extraColour">
            &nbsp;&nbsp;
          </button>
        </div>
        <div class="column">
          <span v-if="data.extra">Extra data</span>
          <span v-else>No extra data</span>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'AutoCheckGrid',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    extraColour() {
      const { extra } = this.data
      return extra ? this.getColour(extra[0].status) : 'is-success'
    },
    extraSection() {
      return this.data.extra ? this.data.extra[0].section : 'risk'
    }
  },
  methods: {
    scroll(id) {
      this.$mxp.track(`autocheck_grid_scroll_to_${id}`)
      const selector = document.querySelector(`#${id}`)
      if (selector) {
        return selector.scrollIntoView({
          behavior: 'smooth'
        })
      }
    },
    getColour(status) {
      if (status === 1) return 'is-warning'
      else if (status === 2) return 'is-danger'
      else return 'is-success'
    }
  }
}
</script>
